<template>
  <Container>
    <Header>
      Buyers
      <template #right>
        <BadgeBtn @click="openBuyer()">
          + Buyer
        </BadgeBtn>
      </template>
    </Header>
    <BuyerTable />
  </Container>
</template>

<script>
import BuyerTable from "../components/tabels/BuyerTable.vue"
import comp from "../components/base"
import { mapActions } from "vuex"

export default {
  name: "Buyers",
  components: {
    ...comp,
    BuyerTable,
  },
  methods: {
    ...mapActions(["openBuyer"]),
  },
}
</script>
