<template>
  <Table>
    <template v-slot:headRow>
      <TH>Name</TH>
      <TH>State</TH>
      <TH>Phone</TH>
      <TH>City</TH>
    </template>
    <template v-slot:body>
      <TR v-for="(item, index) in buyers" :key="index">
        <TD>
          <ButtonGroup>
            <Button @click="openBuyer(item.id)">
              <BIconPencilSquare />
            </Button>
            <Button @click="openDeleteBuyer(item.id)">
              <BIconTrash />
            </Button>
          </ButtonGroup>
        </TD>
        <TD>{{ item.name }}</TD>
        <TD>{{ item.phone }}</TD>
        <TD>{{ item.city }}</TD>
      </TR>
    </template>
  </Table>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import icons from "../../assets/icons"

import comp from "@/components/base"

export default {
  name: "BuyerTable",
  components: {
    ...comp,
    ...icons,
  },
  methods: {
    ...mapActions(["openDeleteBuyer", "openBuyer"]),
  },
  computed: {
    ...mapGetters(["buyers"]),
  },
}
</script>
